export default function setupAxios(axios) {
  axios.interceptors.request.use(
    config => {
        let tkn = localStorage.getItem('jwt')
        config.headers.Authorization = `Bearer ${tkn}`;
      return config;
    },
    err => Promise.reject(err)
  );
}
