import React from "react";
export const ChatTranscript = ({chat}) => {
    return(
        <div className="card">
            <div className="card-header">
                Transcript
            </div>
            <div className="card-body chat-transcript-container">
                {chat.messages.map(message => (
                    <div className={message.type === "bot" ? "chat-bot" : "chat-user"}>{message.message}</div>
                ))}
            </div>
        </div>
    )
}
