import React, {useEffect, useState} from 'react';
import {Button, Col, Form} from "react-bootstrap";
import ImageUploader from "react-images-upload";
import useGlobalState from "../../../../app/modules/globalStateService";
import axios from "axios";

export const ChatSettingsForm = () => {
    const globalState = useGlobalState();
    const [formData, setFormData] = useState(globalState.state.app.currentOffice.settings);

    const submitForm = e => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/providers/settings/${globalState.state.app.currentOffice._id}`, formData)
            .then( r => r.data)
            .then(data => {
                window.location.href = window.location.pathname+"?success=1"
            })
            .catch(error => console.log)    }

    const handleInputChange = e =>{
        const name = e.target.name
        const value = e.target.value
        if(name === 'bookingOffset') {
            setFormData((prevState) => ({
                ...prevState,
                appointments: {...prevState.appointments, [name]: value},
            }));
        }
        else if(name === 'invitation') {
            setFormData((prevState) => ({
                ...prevState,
                widget: {...prevState.widget, [name]: value},
            }));
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onDrop = (picture) =>{
        toBase64(picture[0]) // Path to the image
            .then(
                (response) => {
                    setFormData((prevState) => ({
                        ...prevState,
                        widget: {...prevState.widget, avatar: response},
                    }));
                }
            )
            .catch(
                (error) => {
                    console.log(error); // Logs an error if there was one
                }
            )
    }

    useEffect( () => {
        setFormData(globalState.state.app.currentOffice.settings)
    }, [globalState.state.app.currentOffice])

    return(
        <Form onSubmit={submitForm} onChange={handleInputChange}>
            <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Booking Offset (hours)</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="48"
                    name="bookingOffset"
                    value={formData.appointments.bookingOffset || ""}
                />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Chat Invitation</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Welcome, lets book an appointment!"
                    name="invitation"
                    value={formData.widget.invitation || ""}
                />
            </Form.Group>
            <Form.Row>
                <Form.Label>Chat Avatar</Form.Label>
                {formData.widget.avatar &&
                <img src={formData.widget.avatar} width="150px"/>}
                <ImageUploader
                    withIcon={true}
                    buttonText='Choose Image'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png']}
                    label="Max file size 1mb, accepted: jpg, png, gif"
                    maxFileSize={1000000}
                    singleImage={true}
                    withPreview
                />
            </Form.Row>

            <Button variant="primary" type="submit">
                Save
            </Button>
        </Form>
    )
}
