/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect, useState} from "react";
import { Provider } from "react-redux";
import axios from 'axios';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import {useAuth0} from "../react-auth0-spa";
import {GlobalStateProvider} from "./modules/globalStateService";


export default function App({ store, persistor, basename }) {
  const {loading, getTokenSilently, loginWithRedirect, isAuthenticated, logout} = useAuth0();
  const [localLoad, setLocalLoad] = useState(true)
  const [offices, setOffices] = useState();

  useEffect( () => {
    if(!loading && isAuthenticated){
      getTokenSilently().then(tkn => {
        localStorage.setItem('jwt', tkn)
      })
    }
  }, [loading,])

  useEffect( () => {
    if(!loading && isAuthenticated) {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/offices`)
          .then(r => r.data)
          .then(data => {setOffices(data.offices)})
          .then( () => setLocalLoad(false))
          // .catch( () => logout())
    }
  }, [loading])

  if(loading )
    return <LayoutSplashScreen />

  if(!isAuthenticated && !loading){
    loginWithRedirect()
  }

  if(localLoad)
    return <LayoutSplashScreen />
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <GlobalStateProvider offices={offices}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
      </GlobalStateProvider>
    </Provider>
  );
}
