import React, {useEffect, useState} from "react";
import {LayoutSplashScreen, useSubheader} from "../../_metronic/layout";
import {ChatsTableWidget} from "../../_metronic/_partials/widgets/advance-tables/ChatsTableWidget";
import axios from "axios";
import {ChatTranscript} from "../../_metronic/_partials/widgets/lists/ChatTranscript";
import useGlobalState from "../modules/globalStateService";

export const ChatsPage = () => {
    let globalState = useGlobalState();
    const suhbeader = useSubheader();
    suhbeader.setTitle("Chats");
    const [localLoad, setLocalLoad] = useState(true);
    const [data, setData] = useState();
    const[activeChat, setActiveChat] = useState(null);

    const getLogs = page => {
        setLocalLoad(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/chats/${globalState.state.app.currentOffice._id}/?page=${page}`)
            .then(r => {
                setData(r.data);
                setLocalLoad(false);
            }).catch(e => {
            setLocalLoad(false);
        });
    }

    const viewChatHandler = (chat) => {
        console.log(chat)
        setActiveChat(chat)
    }

    useEffect(() => {
        getLogs(0)
    }, [globalState.state.app.currentOffice])

    if(localLoad){
        return(<LayoutSplashScreen />)
    }

    return (
        <div className="row">
            <div className="col-lg-8">
                <ChatsTableWidget data={data} paginate={getLogs} chatClickHandler={viewChatHandler}/>
            </div>
            <div className="col-lg-4">
                {activeChat && <ChatTranscript chat={activeChat} />}
            </div>
        </div>
    );
};
