import React, {useEffect, useState} from "react";
import {LayoutSplashScreen, useSubheader} from "../../_metronic/layout";
import axios from "axios";
import {QuestionsTableWidget} from "../../_metronic/_partials/widgets/advance-tables/QuestionsTableWidget";
import useGlobalState from "../modules/globalStateService";

export const QuestionsPage = () => {
    let globalState = useGlobalState();
    const suhbeader = useSubheader();
    suhbeader.setTitle("Appointments");
    const [localLoad, setLocalLoad] = useState(true);
    const [data, setData] = useState();

    const getLogs = page => {
        setLocalLoad(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/questions/${globalState.state.app.currentOffice._id}/?page=${page}`)
            .then(r => {
                setData(r.data);
                setLocalLoad(false);
            }).catch(e => {
            setLocalLoad(false);
        });
    }

    useEffect(() => {
        getLogs(0)
    }, [globalState.state.app.currentOffice])

    if(localLoad){
        return(<LayoutSplashScreen />)
    }

    return (
        <>
            <QuestionsTableWidget data={data} paginate={getLogs}/>
        </>
    );
};
