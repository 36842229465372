import React from 'react';
import {Modal, Button} from "react-bootstrap";

const ContactSalesModal = ({show, showHandler}) => {

    return(
        <Modal show={show} onHide={showHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Add a New Office</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>Please contact our sales team to add a new office! info@jointoothly.com</strong>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={showHandler}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ContactSalesModal;
