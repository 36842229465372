import React, {useEffect, useState} from "react";
import {LayoutSplashScreen, useSubheader} from "../../_metronic/layout";
import axios from "axios";
import {Button, Card, Form, ListGroup} from "react-bootstrap";
import {ListsWidget9} from "../../_metronic/_partials/widgets";
import {LeadDetailsList} from "../../_metronic/_partials/widgets/lists/LeadDetailsList";

export const AppointmentDetailsPage = props => {
    const suhbeader = useSubheader();
    suhbeader.setTitle("Lead | ");
    const [localLoad, setLocalLoad] = useState(true);
    const [data, setData] = useState();

    const getLeads = page => {
        setLocalLoad(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/appointments/${props.match.params.id}`)
            .then(r => {
                setData(r.data);
                console.log(r.data);
                setLocalLoad(false);
            }).catch(e => {
            console.log(e);
            setLocalLoad(false);
        });
    }

    useEffect(() => {
        getLeads(0)
    }, [])

    if(localLoad){
        return(<LayoutSplashScreen />)
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    {/*<LeadDetailsList lead={data}/>*/}
                </div>
                <div className="col-md-9">
                    <ListsWidget9/>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-md-8">
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Lead Notes
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    Note about lead with stuff
                                    <p className="text-muted">By Josh K on Jan 8, 2021 at 7:24pm</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    Note about lead with stuff
                                    <p className="text-muted">By Josh K on Jan 8, 2021 at 7:24pm</p>
                                </ListGroup.Item>
                            </ListGroup>
                            <div className="mt-2 px-1">
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>New Note</Form.Label>
                                        <Form.Control as="textarea" rows="3" />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-4">
                    Actions
                </div>
            </div>

        </>
    );
};
