/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect, useState} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import axios from 'axios';
import {useHtmlClassService} from "../../../layout";
import useGlobalState from "../../../../app/modules/globalStateService";

export function WeeklyChats({ className }) {
  const [chart, setChart] = useState()
  const [chatCount, setChatCount] = useState(0);
  const globalState = useGlobalState()

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  const getChartData = () => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/dashboard/chats/${globalState.state.app.currentOffice._id}`)
        .then(r => r.data)
        .then(chartData => {
          setChatCount(chartData.chatCount)
          return chartData
        });
  }

  const renderChart = (options) => {
    const element = document.getElementById("kt_stats_widget_chat_chart");
    if (!element) {
      return;
    }
    const c = new ApexCharts(element, options);
    c.render()
        .then(() => setChart(c))
  }

  const mapDataToOptions = (data) => {
    let dataX = [];
    let dataLabels = [];
    data.chats.map(key => {
      dataLabels.push(key._id);
      dataX.push(key.count)
    })
    return getChartOption(layoutProps, dataX ,dataLabels);
  }

  useEffect(() => {
    getChartData()
        .then( data => mapDataToOptions(data))
        .then(options => renderChart(options))
  }, [layoutProps]);

  useEffect(() => {
    if(chart){
      getChartData()
          .then( data => mapDataToOptions(data))
          .then(options => chart.updateOptions(options))
    }
  }, [globalState.state.app.currentOffice]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <a
              href="#"
              className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5"
            >
              New Chats Last 7 Days
            </a>
            <span className="text-muted font-weight-bold mt-2">
              Chats by day
            </span>
          </div>
          <span className="symbol symbol-priamry symbol-45">
            <span className="symbol-label font-weight-bolder font-size-h6">
              +{chatCount}
            </span>
          </span>
        </div>
        <div
          id="kt_stats_widget_chat_chart"
          className="card-rounded-bottom"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, dataX, dataLabels) {
  const options = {
    series: [
      {
        name: "Chats Started",
        data: dataX
      }
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 1
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBaseSuccess]
    },
    xaxis: {
      categories: dataLabels,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return val;
        }
      }
    },
    colors: [layoutProps.colorsThemeLightSuccess],
    markers: {
      colors: [layoutProps.colorsThemeLightSuccess],
      strokeColor: [layoutProps.colorsThemeBaseSuccess],
      strokeWidth: 3
    }
  };
  return options;
}
