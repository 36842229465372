import React from "react";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import {useAuth0} from "../../../../react-auth0-spa";

const Logout = () => {
  const { loading, isAuthenticated, logout } = useAuth0();

  if(loading){
    return(<LayoutSplashScreen />)
  }
  else if(isAuthenticated) {
    return(logout())
  }
  return(<Redirect to={'/'} />);
};

export default Logout;