/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";

export function QuestionsTableWidget({className, data, paginate}) {
    return (
        <div className={`card card-custom ${className}`}>
            {/* Head */}
            <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Questions</span>
                </h3>
                <div className="card-toolbar">
                    {data.page > 0 &&
                    <button className="btn btn-primary font-weight-bolder font-size-sm mr-3" onClick={ () => paginate(data.page-1)}>Prev</button>
                    }
                    {data.count > (data.page+1 * data.limit) &&
                    <button className="btn btn-primary font-weight-bolder font-size-sm mr-3" onClick={ () => paginate(data.page+1)}>Next</button>
                    }
                </div>
            </div>
            {/* Body */}
            <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                            <tr className="text-left text-uppercase">
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Session</span>
                                </th>
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Question</span>
                                </th>
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Phone</span>
                                </th>
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Email</span>
                                </th>
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Created At</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.questions && data.questions.map(datum => (
                            <tr>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.session}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.question}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.phone}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.email}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {new Date(datum.created_at).toDateString()} @ {new Date(datum.created_at).toLocaleTimeString()}
                                    </span>
                                </td>
                            </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
