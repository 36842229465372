import React, {Suspense, useEffect} from "react";
import {Redirect, Switch} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import {AppointmentsPage} from "./pages/AppointmentsPage";
import {ChatsPage} from "./pages/ChatsPage";
import {QuestionsPage} from "./pages/QuestionsPage";
import {AppointmentDetailsPage} from "./pages/AppointmentDetailsPage";
import Logout from "./pages/logout";
import {SettingsPage} from "./pages/SettingsPage";
import {useAuth0} from "../react-auth0-spa";
import useGlobalState from "./modules/globalStateService";
import {BillingPage} from "./pages/BillingPage";
import {SupportPage} from "./pages/SupportPage";

export default function BasePage() {
    const {loading, user} = useAuth0();
    let globalState = useGlobalState();
    useEffect(() => {
        if(!loading)
            globalState.updateApp({user: user})
    }, [loading])

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                <ContentRoute path="/appointments/:id" component={AppointmentDetailsPage}/>
                <ContentRoute path="/appointments" component={AppointmentsPage}/>
                <ContentRoute path="/questions" component={QuestionsPage}/>
                <ContentRoute path="/responses" component={ChatsPage}/>
                <ContentRoute path="/settings" component={SettingsPage}/>
                <ContentRoute path="/billing" component={BillingPage} />
                <ContentRoute path="/support" component={SupportPage} />
                <ContentRoute path="/logout" component={Logout}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
