import React from "react";
import {Redirect} from "react-router";
import {useAuth0} from "../../react-auth0-spa";

const Logout = props => {
    const { loading, isAuthenticated, logout } = useAuth0();

    if(loading){
        return(<p>Loading</p>)
    }
    else if(isAuthenticated) {
        return(logout())
    }
    return(<Redirect to={'/'} />);
};

export default Logout;
