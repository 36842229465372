import React from "react";
import {DashStats} from "../widgets";
import useGlobalState from "../../../app/modules/globalStateService";
import {WeeklyAppointments} from "../widgets/stats/WeeklyAppointments";
import {WeeklyChats} from "../widgets/stats/WeeklyChats";
import {Button} from "react-bootstrap";
import {NavLink}  from "react-router-dom";
import {BillingOverview} from "../widgets/billing/BillingOverview";

export function ProviderDashboard() {
        let globalState = useGlobalState();

    return (<>
        <div className="row">
            <div className="col-lg-6 col-xxl-4">
                <DashStats className="card-stretch gutter-b"/>
            </div>
            <div className="col-lg-6 col-xxl-8">
                    <WeeklyAppointments className="card-stretch card-stretch-half gutter-b"/>
                    <WeeklyChats className="card-stretch card-stretch-half gutter-b"/>
                {/*<LeadsTableWidget className="card-stretch gutter-b" />*/}
            </div>
        </div>

        <div className="row my-7">
            <div className="col">
                <div className="card">
                    <NavLink to="/responses" className="btn btn-primary p-7 font-weight-bolder">View Chats</NavLink>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <NavLink to="/appointments" className="btn btn-primary p-7 font-weight-bolder">View Appointments</NavLink>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <NavLink to="/questions" className="btn btn-primary p-7 font-weight-bolder">View Questions</NavLink>
                </div>
            </div>
        </div>

        <div className="row my-7">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header py-3">
                        <h3 className="card-title font-weight-bolder text-primary">Widget Code</h3>
                        <div className="card-toolbar">
                        </div>
                    </div>
                    <div className="card-body">
                        <p>Copy and paste this code at the end of the <code>body</code> on your website</p>
                        <pre>
                            <code>
                                &lt;div id="toothly-root"&gt;&lt;/div&gt;<br/>
                                &lt;script&gt;<br/>
                                    window.toothlyProviderID = "{`${globalState.state.app.currentOffice._id}`}";<br/>
                                &lt;/script&gt;<br/>
                                &lt;script src="https://toothly.nyc3.digitaloceanspaces.com/chat/toothly_chat.js"&gt;&lt;/script&gt;
                            </code>
                        </pre>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="card">
                    <div className="card-header py-3">
                        <h3 className="card-title font-weight-bolder text-primary">Billing</h3>
                        <div className="card-toolbar">
                        </div>
                    </div>
                    <div className="card-body">
                        <BillingOverview withLink={true}/>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
