import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Col, Form} from "react-bootstrap";
import ImageUploader from "react-images-upload";
import useGlobalState from "../../../../app/modules/globalStateService";

export const OfficeSettingsForm = () => {
    const globalState = useGlobalState();
    const [formData, setFormData] = useState(globalState.state.app.currentOffice);

    const submitForm = e => {
        e.preventDefault()
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/providers/${globalState.state.app.currentOffice._id}`, formData)
            .then( r => r.data)
            .then(data => {
                window.location.href = window.location.pathname+"?success=1"
                // window.location.reload();
            })
            .catch(error => console.log)
        // Update global state
    }

    const handleInputChange = e =>{
        const addressFields = ['city', 'state', 'zipcode', 'line1', 'line2']
        const name = e.target.name
        const value = e.target.value
        if(addressFields.includes(name)){
            setFormData((prevState) => ({
                ...prevState,
                address: {...prevState.address, [name]: value},
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const onDrop = (picture) =>{
        toBase64(picture[0]) // Path to the image
            .then(
                (response) => {
                    setFormData((prevState) => ({
                        ...prevState,
                        logo: response,
                    }));
                }
            )
            .catch(
                (error) => {
                    console.log(error); // Logs an error if there was one
                }
            )
    }

    useEffect( () => {
        setFormData(globalState.state.app.currentOffice)
    }, [globalState.state.app.currentOffice])

    return(
        <Form onChange={handleInputChange} onSubmit={submitForm}>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridOfficeName">
                    <Form.Label>Office Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Office Name"
                        name="name"
                        value={formData.name || ""}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Admin Email</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={formData.email || ""}/>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridWebsite">
                    <Form.Label>Website URL</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="https://mywebsite.com"
                        name="website"
                        value={formData.website || ""}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPhone">
                    <Form.Label>Office Phone Number</Form.Label>
                    <Form.Control
                        type="tel"
                        placeholder="Phone Number"
                        name="phone"
                        value={formData.phone || ""}/>
                </Form.Group>
            </Form.Row>
            <Form.Group controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control
                    placeholder="1234 Main St"
                    name="line1"
                    value={formData.address.line1 || ""}/>
            </Form.Group>

            <Form.Group controlId="formGridAddress2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                    placeholder="Apartment, studio, or floor"
                    name="line2"
                    value={formData.address.line2 || ""}/>
            </Form.Group>

            <Form.Row>
                <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control name="city" value={formData.address.city || ""}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>State</Form.Label>
                    <Form.Control as="select" name="state" value={formData.address.state || ""}>
                        <option value="">Select a State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                    <Form.Label>Zip</Form.Label>
                    <Form.Control name="zipcode" value={formData.address.zipcode || ""}/>
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Label>Office Logo</Form.Label>
                {formData.logo_url &&
                <img src={formData.logo_url} width="150px"/>}
                <ImageUploader
                    withIcon={true}
                    buttonText='Choose Image'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.gif', '.png']}
                    label="Max file size 1mb, accepted: jpg, png, gif"
                    maxFileSize={1000000}
                    singleImage={true}
                    withPreview
                />
            </Form.Row>

            <Button variant="primary" type="submit">
                Save
            </Button>
        </Form>
    )
}
