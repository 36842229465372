import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../_helpers";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
      footerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          &copy;{" "}
          <a
            href="https://toothly.chat/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            Toothly Care Inc
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <a
            href="https://toothly.chat/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            Privacy
          </a>
          <a
            href="https://toothly.chat/terms.html"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Terms
          </a>
          <Link
            to="/support"
            className="nav-link pl-3 pr-0"
          >
            Support
          </Link>
          {/* begin::Logo */}
          <Link to="" className="brand-logo nav-link pl-4 pr-0">
            <img alt="logo" width="75px" src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/logos/toothly.png")}/>
          </Link>
          {/* end::Logo */}
        </div>
      </div>
    </div>
  );
}
