/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";

export function AppointmentsTableWidget({className, data, paginate}) {
    return (
        <div className={`card card-custom ${className}`}>
            {/* Head */}
            <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark">Appointments</span>
                </h3>
                <div className="card-toolbar">
                    {data.page > 0 &&
                    <button className="btn btn-primary font-weight-bolder font-size-sm mr-3" onClick={ () => paginate(data.page-1)}>Prev</button>
                    }
                    {data.count > data.page+1 * data.limit &&
                    <button className="btn btn-primary font-weight-bolder font-size-sm mr-3" onClick={ () => paginate(data.page+1)}>Next</button>
                    }
                </div>
            </div>
            {/* Body */}
            <div className="card-body pt-0 pb-3">
                <div className="tab-content">
                    <div className="table-responsive">
                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                            <thead>
                            <tr className="text-left text-uppercase">
                                <th className="pl-7" style={{minWidth: "200px"}}>
                                    <span className="text-dark-75">Name</span>
                                </th>
                                <th style={{minWidth: "120px"}}>
                                    <span className="text-dark-75">Phone</span>
                                </th>
                                <th style={{minWidth: "100px"}}>
                                    <span className="text-dark-75">Email</span>
                                </th>
                                <th style={{minWidth: "75px"}}>
                                    <span className="text-dark-75">Date</span>
                                </th>
                                <th style={{minWidth: "75px"}}>
                                    <span className="text-dark-75">Time</span>
                                </th>
                                <th style={{minWidth: "75px"}}>
                                    <span className="text-dark-75">Service</span>
                                </th>
                                <th style={{minWidth: "75px"}}>
                                    <span className="text-dark-75">New Patient</span>
                                </th>
                                <th style={{minWidth: "75px"}}/>
                            </tr>
                            </thead>
                            <tbody>
                            {data.appointments && data.appointments.map(datum => (
                            <tr>
                                <td className="pl-0 py-8">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50 symbol-light mr-4">
                                                <span className="symbol-label">
                                                    <span className="svg-icon svg-icon-primary h-75 align-self-end">
                                                        <SVG src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/General/User.svg")}/>
                                                    </span>
                                                </span>
                                        </div>
                                        <div>
                                            <a href="#"
                                               className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                                                {datum.patient_name}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {datum.phone}
                                        </span>
                                </td>
                                <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {datum.email}
                                        </span>
                                </td>
                                <td>
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {datum.date}
                                        </span>
                                </td>
                                <td>
                                         <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.time}
                                        </span>
                                </td>
                                <td>
                                         <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                             {datum.service}
                                        </span>
                                </td>
                                <td>
                                         <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                        {datum.new_patient}
                                        </span>
                                </td>
                                <td className="pr-0 text-right">
                                    <a href={`/appointments/${datum._id}`} className="btn btn-secondary font-weight-bolder font-size-sm">View
                                        Appointment</a>
                                </td>
                            </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
