/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import useGlobalState from "../../../../app/modules/globalStateService";
import axios from "axios";

export function DashStats({ className }) {
    const globalState = useGlobalState()
    const [stats, setStats] = useState();
    const [localLoad, setLocalLoad] = useState(true)

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/dashboard/stats/${globalState.state.app.currentOffice._id}`)
            .then(r => r.data)
            .then(statData => {
                setStats(statData)
            }).then(() => setLocalLoad(false))
    }, [globalState.state.app.currentOffice])

    if(localLoad){
        return (<></>)
    }
    return (
        <div className={`card card-custom bg-white ${className}`}>
            {/* Header */}
            <div className="card-header border-0 bg-primary py-5">
                <h3 className="card-title font-weight-bolder text-white">Monthly Performance</h3>
                <div className="card-toolbar">
                </div>
            </div>
            {/* Body */}
            <div className="card-body p-0 position-relative overflow-hidden">
                {/* Stat */}
                <div className="card-spacer">
                    <div className="row m-0">
                        <div className="col bg-info px-6 py-8 rounded-xl mb-7 mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Communication/Chat6.svg")}></SVG>
                          </span>
                            <div className="w-100 text-center">
                                <span className="text-dark font-weight-bold font-size-h2 mt-2">
                                    {stats.chatsCount}
                                </span>
                                <br/>
                                <span className="text-dark font-weight-bold font-size-h6 mt-2">
                                    Chats
                                </span>
                            </div>
                        </div>
                        <div className="col bg-info px-6 py-8 rounded-xl mb-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG
                                src={toAbsoluteUrl(
                                    "https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Home/Alarm-clock.svg"
                                )}
                            ></SVG>
                          </span>
                            <div className="w-100 text-center">
                                <span className="text-dark font-weight-bold font-size-h2 mt-2">
                                    {stats.appointmentsCount}
                                </span>
                                <br/>
                                <span className="text-dark font-weight-bold font-size-h6 mt-2">
                                    Appointments
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col bg-info px-6 py-8 rounded-xl mr-7">
                          <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                            <SVG src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Communication/Incoming-mail.svg")}></SVG>
                          </span>
                            <div className="w-100 text-center">
                                <span className="text-dark font-weight-bold font-size-h2 mt-2">
                                    {stats.questionsCount}
                                </span>
                                <br/>
                                <span className="text-dark font-weight-bold font-size-h6 mt-2">
                                    Questions
                                </span>
                            </div>
                        </div>
                        <div className="col px-6 py-8 rounded-xl">
                        </div>
                    </div>
                </div>

                {/* Resize */}
                <div className="resize-triggers">
                    <div className="expand-trigger">
                        <div style={{width: "411px", height: "461px"}}/>
                    </div>
                    <div className="contract-trigger"/>
                </div>
            </div>
        </div>
    );
}
