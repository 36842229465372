import React, {useEffect, useState} from 'react';
import {Card, Tabs, Tab, Alert} from "react-bootstrap";
import {useSubheader} from "../../_metronic/layout";
import {OfficeSettingsForm} from "../../_metronic/_partials/controls/forms/OfficeSettingsForm";
import {ChatSettingsForm} from "../../_metronic/_partials/controls/forms/ChatSettingsForm";

export const SettingsPage = (props) => {
    const [saved, setSaved] = useState(false);
    const suhbeader = useSubheader();
    suhbeader.setTitle("Settings");

    useEffect( () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let success = params.get('success');
        setSaved(success)
        }, [])
    return(
        <>
            {saved &&
            <Alert variant="success" onClose={() => setSaved(false)} dismissible>
                <Alert.Heading className="text-dark">Saved!</Alert.Heading></Alert>}
        <Card>
            <Card.Body>
                <Tabs defaultActiveKey="office" id="uncontrolled-tab-example">
                    <Tab eventKey="office" title="Office Settings" tabClassName="h3">
                        <div className="card-body">
                            <OfficeSettingsForm />
                        </div>
                    </Tab>
                    <Tab eventKey="chat" title="Chat"  tabClassName="h3">
                        <div className="card-body">
                            <ChatSettingsForm />
                        </div>
                    </Tab>
                </Tabs>

            </Card.Body>
        </Card>
        </>
    )
}
