/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Design/Layers.svg"}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/appointments", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/appointments">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Home/Alarm-clock.svg"}/>
            </span>
              <span className="menu-text">Appointments</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/questions", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/questions">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Communication/Incoming-mail.svg"}/>
            </span>
              <span className="menu-text">Questions</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/responses", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/responses">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Communication/Chat1.svg"}/>
            </span>
              <span className="menu-text">Responses</span>
            </NavLink>
          </li>
          {/* Components */}

          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/settings", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Code/Compiling.svg"}/>
            </span>
              <span className="menu-text">Settings</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/billing", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/billing">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Shopping/Dollar.svg"}/>
            </span>
              <span className="menu-text">Billing</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          {/* begin::section */}
          {/*<li className="menu-section ">*/}
          {/*  /!*<h4 className="menu-text">Settings</h4>*!/*/}
          {/*  <i className="menu-icon flaticon-more-v2"></i>*/}
          {/*</li>*/}
          {/* end:: section */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/support", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/support">
            <span className="svg-icon menu-icon">
              <SVG src={"https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Code/Question-circle.svg"}/>
            </span>
              <span className="menu-text">Support</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
