import React from "react";
import {Card} from "react-bootstrap";

export const SupportPage = () => {
    return(
        <Card>
            <Card.Header>
                Support
            </Card.Header>
            <Card.Body>
                <h3>This page is under construction</h3>
                <p>Please contact support via email support@jointoothly.com</p>
            </Card.Body>
        </Card>
    )
}
