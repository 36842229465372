import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Card} from "react-bootstrap";
import {BillingOverview} from "../../_metronic/_partials/widgets/billing/BillingOverview";

export const BillingPage = (props) => {
    const [url, setUrl] = useState('#')

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/billing-portal`)
            .then(r => r.data)
            .then(data => setUrl(data.url))
    }, [])

    return(
        <>
            <Card>
                <Card.Header>
                    Billing
                </Card.Header>
                <Card.Body>
                    <BillingOverview withLink={false}/>
                    <a href={url} className="btn btn-primary">Go to Billing Portal</a>
                </Card.Body>
            </Card>
        </>
    )
}
