import React, {useEffect, useState} from 'react';
import axios from 'axios';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {NavLink} from "react-router-dom";

export const BillingOverview = ({withLink}) => {
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/providers/subscriptions`)
            .then(r => r.data)
            .then(data => setSubscriptions(data.data))
    }, [])

    return(
        <>
            {
                subscriptions.map(sub => (
                    <>
                    <div className="d-flex align-items-center mb-10">
                        <div className="symbol symbol-40 symbol-light-primary mr-5">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                              <SVG
                                  className="h-75 align-self-end"
                                  src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Shopping/Dollar.svg")}
                              ></SVG>
                            </span>
                          </span>
                        </div>

                        <div className="d-flex flex-column font-weight-bold">
                            <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                            >
                                Next Billing Date
                            </a>
                            <span className="text-muted">{new Date(sub.current_period_end*1000).toDateString()}</span>
                        </div>
                    </div>
                        {sub.trial_end &&
                        <div className="d-flex align-items-center mb-10">
                            <div className="symbol symbol-40 symbol-light-primary mr-5">
                          <span className="symbol-label">
                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                              <SVG
                                  className="h-75 align-self-end"
                                  src={toAbsoluteUrl("https://toothly.nyc3.digitaloceanspaces.com/dashboard/media/svg/icons/Home/Clock.svg")}
                              ></SVG>
                            </span>
                          </span>
                            </div>

                            <div className="d-flex flex-column font-weight-bold">
                                <a
                                    href="#"
                                    className="text-dark text-hover-primary mb-1 font-size-lg"
                                >
                                    Trial End Date
                                </a>
                                <span className="text-muted">{new Date(sub.trial_end*1000).toDateString()}</span>
                            </div>
                        </div>}
                    </>
                ))
            }
            {withLink && <NavLink to="/billing">Go to Billing</NavLink>}
        </>
    )
}
